// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  padding: 1px 0 0 0;
  background-color: var(--mdc-protected-button-container-color);
  box-shadow: var(--mdc-protected-button-container-elevation-shadow);
}
a mat-icon {
  color: #005cbb;
}

@media print {
  a mat-icon {
    margin-top: 6px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/social-button/social-button.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,6DAAA;EACA,kEAAA;AACJ;AACI;EACE,cAAA;AACN;;AAGA;EAEQ;IACI,eAAA;EADV;AACF","sourcesContent":["a {\n    padding: 1px 0 0 0;\n    background-color: var(--mdc-protected-button-container-color);\n    box-shadow: var(--mdc-protected-button-container-elevation-shadow);\n\n    mat-icon {\n      color: #005cbb;\n    }\n}\n\n@media print {\n    a {\n        mat-icon {\n            margin-top: 6px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
