// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  background-color: rgb(100, 100, 100);
}
:host * {
  color: white;
}

.brands {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  margin: 0 -2em;
}
.brands > .brand {
  padding: 0 2em;
  margin-bottom: 2em;
}
.brands > .brand > img {
  max-height: 32px;
}

@media (min-width: 600px) {
  .brands > .brand > img {
    max-height: 48px;
  }
}
@media (min-width: 960px) {
  .brands > .brand > img {
    max-height: 52px;
  }
}
@media (min-width: 1280px) {
  .brands > .brand > img {
    max-height: 64px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/content/brands/brands.component.scss","webpack://./src/scss/vars.scss"],"names":[],"mappings":"AAIA;EACI,cAAA;EACA,oCCFQ;ADDZ;AAKI;EACI,YAAA;AAHR;;AAOA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,qBAAA;EACA,cAAA;AAJJ;AAMI;EACI,cAAA;EACA,kBApBC;AAgBT;AAKQ;EACI,gBAAA;AAHZ;;AAQA;EACI;IACI,gBAAA;EALN;AACF;AAQA;EACI;IACI,gBAAA;EANN;AACF;AASA;EACI;IACI,gBAAA;EAPN;AACF","sourcesContent":["@import '../../../scss/import';\n\n$gutter: 2em;\n\n:host {\n    display: block;\n    background-color: $dark-grey;\n\n    * {\n        color: white;\n    }\n}\n\n.brands {\n    display: flex;\n    flex-flow: row wrap;\n    justify-content: center;\n    align-content: center;\n    margin: 0 ($gutter * -1);\n\n    > .brand {\n        padding: 0 $gutter;\n        margin-bottom: $gutter;\n        > img {\n            max-height: 32px;\n        }\n    }\n}\n\n@media (min-width: map_get($breakpoints, sm)) {\n    .brands > .brand > img {\n        max-height: 48px;\n    }\n}\n\n@media (min-width: map_get($breakpoints, md)) {\n    .brands > .brand > img {\n        max-height: 52px;\n    }\n}\n\n@media (min-width: map_get($breakpoints, lg)) {\n    .brands > .brand > img {\n        max-height: 64px;\n    }\n}\n","$border-color: #dee2e6;\n$text-color: #53638c;\n\n$light-grey: rgb(215, 215, 215);\n$dark-grey: rgb(100, 100, 100);\n\n$breakpoints: (\n    sm: 600px,\n    md: 960px,\n    lg: 1280px,\n    xl: 1920px,\n);\n\n$gutter: 1.5em;\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
