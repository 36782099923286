import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Section } from '@models';
import { Store } from '@ngxs/store';
import { ActivateSection } from '@actions';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
    @Input()
    drawer: MatSidenav;

    isVertical: boolean;
    Section = Section;

    constructor(private store: Store) {}

    ngOnInit() {
        this.isVertical = this.drawer !== undefined;
    }

    scrollTo(section: Section) {
        if (this.drawer !== undefined) {
            return this.drawer.close().then(() => {
                this.store.dispatch(new ActivateSection(section));
            });
        }
        this.store.dispatch(new ActivateSection(section));
    }
}
