import { Injectable, Renderer2 } from '@angular/core';
import { filter, skipUntil } from 'rxjs/operators';
import { timer, BehaviorSubject } from 'rxjs';
import { Store } from '@ngxs/store';
import { StartScroll, StopScroll, OnScroll } from '@actions';
import { LayoutState } from '../states/layout.state';

@Injectable()
export class ScrollService {
    static readonly THRESHOLD = 100;

    private isScrolling = false;
    private renderer: Renderer2;
    private scrollTop = new BehaviorSubject<number>(0);

    constructor(private store: Store) {}

    setRenderer(renderer: Renderer2) {
        if (this.renderer) {
            return;
        }
        this.renderer = renderer;
        this.renderer.listen(window.document, 'touchmove', () => this.onScroll());
        this.renderer.listen(window.document, 'scroll', () => this.onScroll());
    }

    onScroll() {
        let scrollTop = 0;

        if (window.pageYOffset !== undefined) {
            scrollTop = window.pageYOffset;
        } else if (window.document.hasOwnProperty('documentElement')) {
            scrollTop = window.document.documentElement.scrollTop;
        } else if (window.document.body.hasOwnProperty('parentNode')) {
            scrollTop = window.document.body.parentNode['scrollTop'];
        } else {
            scrollTop = window.document.body.scrollTop;
        }

        if (scrollTop > ScrollService.THRESHOLD && this.isScrolling === false) {
            this.isScrolling = true;
            this.store.dispatch(new StartScroll(scrollTop));
        } else if (scrollTop <= ScrollService.THRESHOLD && this.isScrolling === true) {
            this.isScrolling = false;
            this.store.dispatch(new StopScroll(scrollTop));
        } else {
            this.scrollTop.next(scrollTop);
        }

        this.scrollTop
            .pipe(skipUntil(timer(1000)))
            .pipe(filter(t => t !== this.store.selectSnapshot(LayoutState.scrollTop)))
            .subscribe((t: number) => {
                this.store.dispatch(new OnScroll(t));
            });
    }
}
