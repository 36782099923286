import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Message } from '@models';
import { SlackService } from '@services';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
    providers: [SlackService],
})
export class ChatComponent implements OnInit {
    public form: UntypedFormGroup;

    constructor(public dialogRef: MatDialogRef<ChatComponent>, private snackBar: MatSnackBar, private slackService: SlackService) {}

    ngOnInit() {
        this.form = new UntypedFormGroup({
            name: new UntypedFormControl('', [Validators.required]),
            email: new UntypedFormControl('', [Validators.required, Validators.email]),
            phone: new UntypedFormControl('', []),
            message: new UntypedFormControl('', [Validators.required]),
        });
    }

    onSubmit() {
        const message = new Message(this.form.value['name'], this.form.value['email'], this.form.value['phone'], this.form.value['message']);
        const snackBarConfig: MatSnackBarConfig = {
            duration: 25000,
        };

        this.slackService.sendMessage(message).subscribe(
            () => {
                this.snackBar.open('Thanks! I will contact you as soon as possible.', undefined, snackBarConfig);
                setTimeout(() => {
                    this.onCancel();
                }, 2500);
            },
            () => {
                this.snackBar.open('Whoops! Something went wrong...', undefined, {
                    ...snackBarConfig,
                    panelClass: 'error',
                });
            },
        );
    }

    onCancel() {
        this.dialogRef.close();
    }
}
