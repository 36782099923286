import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'app-social-button',
    templateUrl: './social-button.component.html',
    styleUrls: ['./social-button.component.scss'],
})
export class SocialButtonComponent {
    @ViewChild('button')
    button: ElementRef;

    @Input()
    svgIcon: string;
    @Input()
    icon: string;
    @Input()
    uri: string;
    @Input()
    title: string;
    @Input()
    label: string;
}
