// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
:host:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: block;
  background-color: white;
  background-image: url(/assets/img/background.png);
  opacity: 0.4;
}
:host .row > .col-2 .mat-mdc-card .mat-mdc-list-item {
  color: #53638c;
  height: 32px;
}`, "",{"version":3,"sources":["webpack://./src/app/content/skills/skills.component.scss","webpack://./src/scss/vars.scss"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,kBAAA;EACA,yCAAA;EACA,4CAAA;AADJ;AAGI;EACI,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,cAAA;EACA,uBAAA;EACA,iDAAA;EACA,YAAA;AADR;AAII;EACI,cCtBK;EDuBL,YAAA;AAFR","sourcesContent":["@import '../../../scss/import';\n\n:host {\n    display: block;\n    position: relative;\n    border-top: 1px solid rgba(0, 0, 0, 0.05);\n    border-bottom: 1px solid rgba(0, 0, 0, 0.05);\n\n    &:before {\n        content: '';\n        position: absolute;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n        z-index: -1;\n        display: block;\n        background-color: white;\n        background-image: url(/assets/img/background.png);\n        opacity: 0.4;\n    }\n\n    .row > .col-2 .mat-mdc-card .mat-mdc-list-item {\n        color: $text-color;\n        height: 32px;\n    }\n}\n","$border-color: #dee2e6;\n$text-color: #53638c;\n\n$light-grey: rgb(215, 215, 215);\n$dark-grey: rgb(100, 100, 100);\n\n$breakpoints: (\n    sm: 600px,\n    md: 960px,\n    lg: 1280px,\n    xl: 1920px,\n);\n\n$gutter: 1.5em;\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
