import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { Project } from '@models';
import { ProjectsState } from '@states';
import { GetProjects } from '@actions';

@Component({
    selector: 'app-featured',
    templateUrl: './featured.component.html',
    styleUrls: ['./featured.component.scss'],
})
export class FeaturedComponent implements OnInit {
    @Select(ProjectsState.loading)
    loading$: Observable<boolean>;

    @Select(ProjectsState.featured)
    projects$: Observable<Project[]>;

    constructor(private store: Store) {}

    ngOnInit() {
        this.store.dispatch(new GetProjects());
    }
}
