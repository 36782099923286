import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[appSection]',
})
export class SectionDirective implements OnInit {
    static readonly DEFAULT_PADDING = 5;

    @Input('appSection')
    padding: number;

    constructor(private el: ElementRef) {}

    ngOnInit() {
        const padding = (this.padding || SectionDirective.DEFAULT_PADDING) + 'em';
        this.el.nativeElement.style.paddingTop = padding;
        this.el.nativeElement.style.paddingBottom = padding;
    }
}
