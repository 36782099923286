export class Project {
    id: number;
    featured = false;
    domain: string;
    name: string;
    image: string;
    description: string;
    link: string;
    highlights: string[];
    active: boolean;

    constructor(id: number, domain: string, name: string, image: string, description: string, link: string, featured = false, highlights = []) {
        this.id = id;
        this.domain = domain;
        this.name = name;
        this.image = image;
        this.description = description;
        this.link = link;
        this.featured = featured;
        this.highlights = highlights;
        this.active = false;
    }

    isFeatured() {
        return this.featured;
    }

    isActive() {
        return this.active;
    }

    getImageUri(webp = false) {
        return 'assets/img/projects/' + (webp ? this.image.replace('.png', '.webp') : this.image);
    }
}
