import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { SkillGroup } from '@models';
import { GetSkills } from '@actions';
import { SkillsState } from '@states';

@Component({
    selector: 'app-skills',
    templateUrl: './skills.component.html',
    styleUrls: ['./skills.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkillsComponent implements OnInit {
    @Select(SkillsState.loading)
    loading$: Observable<boolean>;

    @Select(SkillsState.groups)
    groups$: Observable<SkillGroup[]>;

    constructor(private store: Store) {}

    ngOnInit() {
        this.store.dispatch(new GetSkills());
    }
}
