import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from '@environment';

import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBottomSheetModule, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';

import { LoggerService, ScrollService } from '@services';

import { AppComponent } from './app.component';
import { BackgroundComponent } from './layout/background/background.component';
import { MenuComponent } from './layout/menu/menu.component';
import { IntroComponent } from './content/intro/intro.component';
import { ParallaxComponent } from './shared/components/parallax/parallax.component';
import { SocialButtonComponent } from './shared/components/social-button/social-button.component';
import { AboutMeComponent } from './content/about-me/about-me.component';
import { QuotesComponent } from './content/quotes/quotes.component';
import { ChatComponent } from '@dialogs';
import { ServicesComponent } from './content/services/services.component';
import { PortfolioComponent } from './content/portfolio/portfolio.component';
import { FeaturedComponent } from './content/featured/featured.component';
import { CertificatesTrainingComponent } from './content/certificates-training/certificates-training.component';
import { SkillsComponent } from './content/skills/skills.component';
import { BrandsComponent } from './content/brands/brands.component';
import { ProjectComponent } from './shared/components/project/project.component';
import { FadeInScrollDirective } from './shared/directives/fade-in-scroll.directive';
import { GoToTopComponent } from './layout/go-to-top/go-to-top.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SectionDirective } from './shared/directives/section.directive';
import { AddictedComponent } from './content/addicted/addicted.component';

import { ProjectsState, QuotesState, SkillsState, LayoutState } from '@states';
import { HeaderComponent } from './layout/header/header.component';
import { SidenavComponent } from './layout/sidenav/sidenav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ShowcaseComponent } from './content/showcase/showcase.component';
import { ShowcaseButtonComponent } from './content/showcase/button/button.component';
import { LanguagesComponent } from './content/languages/languages.component';
import { HistoryComponent } from './content/history/history.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

registerLocaleData(localeEn, 'en');

@NgModule({
    declarations: [
        AppComponent,
        BackgroundComponent,
        MenuComponent,
        IntroComponent,
        ParallaxComponent,
        SocialButtonComponent,
        AboutMeComponent,
        QuotesComponent,
        ChatComponent,
        ServicesComponent,
        PortfolioComponent,
        FeaturedComponent,
        CertificatesTrainingComponent,
        SkillsComponent,
        BrandsComponent,
        ProjectComponent,
        FadeInScrollDirective,
        GoToTopComponent,
        FooterComponent,
        SectionDirective,
        AddictedComponent,
        HeaderComponent,
        SidenavComponent,
        ShowcaseComponent,
        ShowcaseButtonComponent,
        LanguagesComponent,
        HistoryComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        environment.production ? ServiceWorkerModule.register('ngsw-worker.js') : [],
        MatIconModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatBottomSheetModule,
        MatProgressBarModule,
        MatCardModule,
        MatChipsModule,
        LayoutModule,
        NgxsModule.forRoot([QuotesState, SkillsState, ProjectsState, LayoutState], { developmentMode: !environment.production }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    ],
    providers: [
        LoggerService,
        ScrollService,
        {
            provide: LOCALE_ID,
            useValue: 'en',
        },
        {
            provide: MatBottomSheetRef,
            useValue: {},
        },
        {
            provide: MAT_BOTTOM_SHEET_DATA,
            useValue: {},
        },
        provideAnimationsAsync(),
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
