import { Injectable } from '@angular/core';
@Injectable()
export class LoggerService {
    public logMessage(message: string) {
        console.log(message);
    }

    public logError(message: string) {
        console.error(message);
    }
}
