import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appFadeInScroll]',
})
export class FadeInScrollDirective implements AfterViewInit {
    @Input()
    fadeInDuration = 2500;

    private displayed = false;

    constructor(private element: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit() {
        if (!this.isInView()) {
            this.renderer.setStyle(this.element.nativeElement, 'opacity', 0);
            this.onScroll();
            window.document.addEventListener('touchmove', () => this.onScroll());
            window.document.addEventListener('scroll', () => this.onScroll());
        } else {
            this.displayed = true;
            this.fadeIn();
        }
    }

    onScroll() {
        if (this.displayed) {
            return;
        }

        if (this.isInView()) {
            this.displayed = true;
            this.fadeIn();
        }
    }

    private fadeIn() {
        const start = new Date().getTime();

        const timer = setInterval(() => {
            const step = Math.min(1, (new Date().getTime() - start) / this.fadeInDuration);

            this.renderer.setStyle(this.element.nativeElement, 'opacity', step);

            if (step === 1) {
                clearInterval(timer);
            }
        }, 25);
    }

    private isInView() {
        const doc = document.documentElement;
        const docViewTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        const docViewBottom = docViewTop + document.body.offsetHeight;

        const elmTop = this.element.nativeElement.offsetTop;
        const elmHeight = this.element.nativeElement.offsetHeight;
        const elmBottom = elmTop + elmHeight;

        return elmBottom <= docViewBottom && elmTop >= docViewTop;
    }
}
