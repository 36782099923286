// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: fixed;
  right: 1em;
  bottom: 1em;
  z-index: 999;
}
:host button {
  background-color: #2196f3;
  color: white;
  border-radius: 50%;
  border: 2px solid white;
  width: 42px;
  height: 42px;
}
:host button .mat-icon {
  margin-top: -3px;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/go-to-top/go-to-top.component.scss"],"names":[],"mappings":"AAGA;EACI,cAAA;EACA,eAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;AAFJ;AAII;EACI,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;AAFR;AAIQ;EACI,gBAAA;AAFZ","sourcesContent":["@use '@angular/material' as mat;\n@import '../../../scss/import';\n\n:host {\n    display: block;\n    position: fixed;\n    right: 1em;\n    bottom: 1em;\n    z-index: 999;\n\n    button {\n        background-color: mat.m2-get-color-from-palette($candy-app-primary);\n        color: white;\n        border-radius: 50%;\n        border: 2px solid white;\n        width: 42px;\n        height: 42px;\n\n        .mat-icon {\n            margin-top: -3px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
