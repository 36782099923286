export class BackgroundFactory {
    public x;
    public y;
    public rad;
    public rgba;
    public vx;
    public vy;

    public constructor(colors, w, h) {
        this.x = Math.round(Math.random() * w);
        this.y = Math.round(Math.random() * h);
        this.rad = Math.round(Math.random()) + 1;
        this.rgba = colors[Math.round(Math.random() * 3)];
        this.vx = Math.round(Math.random() * 3) - 1.5;
        this.vy = Math.round(Math.random() * 3) - 1.5;
    }
}
