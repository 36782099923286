// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  background-color: rgb(100, 100, 100);
}
:host * {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/footer/footer.component.scss"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,oCAAA;AADJ;AAGI;EACI,YAAA;AADR","sourcesContent":["@import '../../../scss/import';\n\n:host {\n    display: block;\n    background-color: $dark-grey;\n\n    * {\n        color: white;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
