import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
    ActivateSection,
    CloseSidenav,
    DismissShowcase,
    GetShowcaseDismissed,
    OnBreakpoint,
    OnScroll,
    OpenSidenav,
    StartScroll,
    StopScroll,
    ToggleSidenav,
} from '@actions';
import { Breakpoints } from '@angular/cdk/layout';
import { Section } from '@models';

export interface LayoutStateModel {
    sidenavOpen: boolean;
    scrolling: boolean;
    scrollTop: number;
    smallScreen: boolean;
    activeSection: Section;
    showcaseDismissedAt: Date;
}

@State<LayoutStateModel>({
    name: 'layout',
    defaults: {
        sidenavOpen: false,
        scrolling: false,
        scrollTop: 0,
        smallScreen: false,
        activeSection: Section.INTRO,
        showcaseDismissedAt: null,
    },
})
export class LayoutState {
    private readonly LS_SHOWCASE_DISMISSED_KEY = 'showcase-dismissed-at';

    @Action(OpenSidenav)
    openSidenav(ctx: StateContext<LayoutStateModel>) {
        const state = ctx.getState();
        return ctx.setState({
            ...state,
            sidenavOpen: true,
        });
    }

    @Action(CloseSidenav)
    closeSidenav(ctx: StateContext<LayoutStateModel>) {
        const state = ctx.getState();
        return ctx.setState({
            ...state,
            sidenavOpen: false,
        });
    }

    @Action(ToggleSidenav)
    toggleSidenav(ctx: StateContext<LayoutStateModel>) {
        const state = ctx.getState();
        return ctx.setState({
            ...state,
            sidenavOpen: !state.sidenavOpen,
        });
    }

    @Action(StartScroll)
    startScroll(ctx: StateContext<LayoutStateModel>, action: StartScroll) {
        const state = ctx.getState();
        return ctx.setState({
            ...state,
            scrolling: true,
            scrollTop: action.scrollTop,
        });
    }

    @Action(StopScroll)
    stopScroll(ctx: StateContext<LayoutStateModel>, action: StopScroll) {
        const state = ctx.getState();
        return ctx.setState({
            ...state,
            scrolling: false,
            scrollTop: action.scrollTop,
        });
    }

    @Action(OnScroll)
    onScroll(ctx: StateContext<LayoutStateModel>, action: OnScroll) {
        const state = ctx.getState();
        return ctx.setState({
            ...state,
            scrollTop: action.scrollTop,
        });
    }

    @Action(OnBreakpoint)
    onBreakpoint(ctx: StateContext<LayoutStateModel>, action: OnBreakpoint) {
        const state = ctx.getState();
        return ctx.setState({
            ...state,
            smallScreen: action.state.breakpoints.hasOwnProperty(Breakpoints.HandsetPortrait) && action.state.breakpoints[Breakpoints.HandsetPortrait] === true,
        });
    }

    @Action(ActivateSection)
    activateSection(ctx: StateContext<LayoutStateModel>, action: ActivateSection) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            activeSection: action.section,
        });
        const anchor = '#' + Section[action.section].toLowerCase().replace('_', '-');
        const nativeElm = window.document.querySelector(anchor);
        console.log(anchor, nativeElm);
        if (nativeElm !== undefined) {
            nativeElm.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    }

    @Action(DismissShowcase)
    dismissShowcase(ctx: StateContext<LayoutStateModel>) {
        const state = ctx.getState();
        const dismissedAt: Date = new Date();
        if (window.localStorage) {
            window.localStorage.setItem(this.LS_SHOWCASE_DISMISSED_KEY, dismissedAt.toISOString());
        }
        return ctx.setState({
            ...state,
            showcaseDismissedAt: dismissedAt,
        });
    }

    @Action(GetShowcaseDismissed)
    getShowcaseDismissed(ctx: StateContext<LayoutStateModel>) {
        let dismissedAt: Date = null;
        if (window.localStorage) {
            const value = window.localStorage.getItem(this.LS_SHOWCASE_DISMISSED_KEY);
            if (value !== null) {
                dismissedAt = new Date(value);
            }
        }
        const state = ctx.getState();
        return ctx.setState({
            ...state,
            showcaseDismissedAt: dismissedAt,
        });
    }

    @Selector()
    public static sidenavOpen(state: LayoutStateModel): boolean {
        return state.sidenavOpen;
    }

    @Selector()
    public static scrolling(state: LayoutStateModel): boolean {
        return state.scrolling;
    }

    @Selector()
    public static scrollTop(state: LayoutStateModel): number {
        return state.scrollTop;
    }

    @Selector()
    public static smallScreen(state: LayoutStateModel): boolean {
        return state.smallScreen;
    }

    @Selector()
    public static activeSection(state: LayoutStateModel): Section {
        return state.activeSection;
    }

    @Selector()
    public static showcaseDismissedAt(state: LayoutStateModel): Date {
        return state.showcaseDismissedAt;
    }
}
