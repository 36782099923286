import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { LoggerService } from '@services/logger.service';
import { Message } from '@models/message';

@Injectable()
export class SlackService {
    private webhookUrl = 'https://hooks.slack.com/services/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';

    constructor(private loggerService: LoggerService, private http: HttpClient) {}

    public sendMessage(message: Message): Observable<boolean> {
        const options = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            responseType: 'text',
        };
        const text = 'New message from <mailto:' + message.email + '|' + message.name + ' <' + message.email + '>>\n\n' + message.message;
        const payload = {
            text: text,
            attachments: [
                {
                    fallback: '',
                    pretext: '',
                    color: '#53638C',
                    fields: [
                        {
                            title: 'Name',
                            value: message.name,
                            short: false,
                        },
                        {
                            title: 'Email',
                            value: message.email,
                            short: false,
                        },
                        {
                            title: 'Phone',
                            value: message.phone,
                            short: false,
                        },
                    ],
                },
            ],
        };

        return (
            this.http
                // @ts-ignore
                .post(this.webhookUrl, 'payload=' + JSON.stringify(payload), options)
                .pipe(
                    map(success => {
                        this.loggerService.logMessage('Successfully submitted to Slack');
                        message.message = '';
                        return true;
                    }),
                )
                .pipe(
                    catchError((error: HttpResponse<null> | any) => {
                        this.loggerService.logError('Unable to submit to Slack: ' + error.statusText);
                        return throwError(false);
                    }),
                )
        );
    }
}
