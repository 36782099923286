import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import SimpleParallax, { IParallaxSettings } from 'simple-parallax-js';

@Component({
    selector: 'app-parallax',
    templateUrl: './parallax.component.html',
    styleUrls: ['./parallax.component.scss'],
})
export class ParallaxComponent implements OnInit, AfterViewInit {
    @Input()
    src: string;

    imageUrl: string;

    private parallax: SimpleParallax;

    constructor() {}

    ngOnInit() {
        this.imageUrl = this.src;
        if (document.documentElement.className.indexOf('no-webp') === -1) {
            this.imageUrl = this.src.replace('.jpg', '.webp');
        }
    }

    ngAfterViewInit() {
        const image = document.getElementsByClassName('thumbnail');
        const settings: IParallaxSettings = {
            scale: 1.4,
            maxTransition: 80,
            orientation: 'down',
        };
        this.parallax = new SimpleParallax(image, settings);
    }
}
