// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile {
  max-width: 280px;
  position: relative;
}
.profile > img {
  max-width: 100%;
}
.profile:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(25, 25, 25, 0.7);
}
.profile > .overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.profile > .overlay > img {
  max-width: 70px;
}
.profile > .overlay > h3,
.profile > .overlay > h4 {
  color: white;
  margin: 0.25rem 0 0 0;
  white-space: nowrap;
}
.profile > button {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  z-index: 3;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/sidenav/sidenav.component.scss"],"names":[],"mappings":"AAEA;EACI,gBAHe;EAIf,kBAAA;AADJ;AAGI;EACI,eAAA;AADR;AAII;EACI,WAAA;EACA,cAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,uCAAA;AAFR;AAKI;EACI,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;EACA,UAAA;AAHR;AAKQ;EACI,eAAA;AAHZ;AAMQ;;EAEI,YAAA;EACA,qBAAA;EACA,mBAAA;AAJZ;AAcI;EACI,kBAAA;EACA,YAAA;EACA,cAAA;EACA,UAAA;EACA,YAAA;AAZR","sourcesContent":["$drawer-max-width: 280px;\n\n.profile {\n    max-width: $drawer-max-width;\n    position: relative;\n\n    > img {\n        max-width: 100%;\n    }\n\n    &:after {\n        content: '';\n        display: block;\n        position: absolute;\n        top: 0;\n        left: 0;\n        width: 100%;\n        height: 100%;\n        z-index: 1;\n        background-color: rgba(25, 25, 25, 0.7);\n    }\n\n    > .overlay {\n        position: absolute;\n        left: 50%;\n        top: 50%;\n        transform: translate(-50%, -50%);\n        z-index: 2;\n\n        > img {\n            max-width: calc($drawer-max-width / 4);\n        }\n\n        > h3,\n        > h4 {\n            color: white;\n            margin: 0.25rem 0 0 0;\n            white-space: nowrap;\n        }\n\n        > h3 {\n        }\n\n        > h4 {\n        }\n    }\n\n    > button {\n        position: absolute;\n        top: 0.25rem;\n        right: 0.25rem;\n        z-index: 3;\n        color: white;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
