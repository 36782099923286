import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { SkillService } from '@services';
import { SkillGroup } from '@models';
import { GetSkills } from '@actions';

interface SkillsStateModel {
    groups: SkillGroup[];
    loading: boolean;
}

@Injectable()
@State<SkillsStateModel>({
    name: 'skills',
    defaults: {
        groups: [],
        loading: true,
    },
})
export class SkillsState {
    constructor(private skillService: SkillService) {}

    @Action(GetSkills)
    getSkills(ctx: StateContext<SkillsStateModel>) {
        return this.skillService.getSkills().pipe(
            tap((groups: SkillGroup[]) => {
                const state = ctx.getState();
                return ctx.setState({
                    ...state,
                    groups: groups,
                    loading: false,
                });
            }),
        );
    }

    @Selector()
    public static loading(state: SkillsStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static groups(state: SkillsStateModel): SkillGroup[] {
        return state.groups;
    }
}
