import { Component, OnInit } from '@angular/core';
import { BackgroundFactory } from './background.factory';
import { environment } from '@environment';

@Component({
    selector: 'app-background',
    templateUrl: './background.component.html',
    styleUrls: ['./background.component.scss'],
})
export class BackgroundComponent implements OnInit {
    public width;
    public height;

    private canvas;
    private ctx;
    private particles = [];
    private patriclesNum = 25;
    private colors = ['#A3E4EB', '#53638C', '#D2D2D2'];
    private timer;

    ngOnInit() {
        if (!environment.background) {
            return;
        }

        this.width = window.innerWidth;
        this.height = window.innerHeight - document.querySelector('mat-toolbar.header').clientHeight;

        if (this.width >= 768) {
            this.patriclesNum = 50;
        }

        if (this.width >= 1024) {
            this.patriclesNum = 125;
        }

        this.onResize();

        window.addEventListener('resize', () => {
            clearTimeout(this.timer);

            this.timer = setTimeout(() => {
                this.onResize();
            }, 250);
        });
    }

    private onResize() {
        this.width = window.innerWidth;
        this.height = window.innerHeight - document.querySelector('mat-toolbar.header').clientHeight;

        this.particles = [];

        for (let i = 0; i < this.patriclesNum; i++) {
            this.particles.push(new BackgroundFactory(this.colors, this.width, this.height));
        }

        this.canvas = document.querySelector('canvas');
        this.ctx = this.canvas.getContext('2d');
        this.loop();
    }

    private loop() {
        this.draw();
        this.requestAnimFrame();

        setTimeout(() => {
            this.loop();
        }, 1000 / 60);
    }

    private draw() {
        let i, j;

        this.ctx.clearRect(0, 0, this.width, this.height);
        this.ctx.globalCompositeOperation = 'lighter';

        for (i = 0; i < this.patriclesNum; i++) {
            const temp = this.particles[i];
            let factor = 1;

            for (j = 0; j < this.patriclesNum; j++) {
                const temp2 = this.particles[j];
                this.ctx.linewidth = 0.5;

                if (temp.rgba === temp2.rgba && this.findDistance(temp, temp2) < 50) {
                    this.ctx.strokeStyle = temp.rgba;
                    this.ctx.beginPath();
                    this.ctx.moveTo(temp.x, temp.y);
                    this.ctx.lineTo(temp2.x, temp2.y);
                    this.ctx.stroke();
                    factor++;
                }
            }

            this.ctx.fillStyle = temp.rgba;
            this.ctx.strokeStyle = temp.rgba;

            this.ctx.beginPath();
            this.ctx.arc(temp.x, temp.y, temp.rad * factor, 0, Math.PI * 2, true);
            this.ctx.fill();
            this.ctx.closePath();

            this.ctx.beginPath();
            this.ctx.arc(temp.x, temp.y, (temp.rad + 5) * factor, 0, Math.PI * 2, true);
            this.ctx.stroke();
            this.ctx.closePath();

            temp.x += temp.vx;
            temp.y += temp.vy;

            if (temp.x > this.width) {
                temp.x = 0;
            }

            if (temp.x < 0) {
                temp.x = this.width;
            }

            if (temp.y > this.height) {
                temp.y = 0;
            }

            if (temp.y < 0) {
                temp.y = this.height;
            }
        }
    }

    private findDistance(p1, p2) {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }

    private requestAnimFrame() {
        return (
            window.requestAnimationFrame ||
            // TODO Fixme
            // window.webkitRequestAnimationFrame ||
            function(callback) {
                window.setTimeout(callback, 1000 / 60);
            }
        );
    }
}
