// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  background-color: #2196f3;
}
:host * {
  color: white !important;
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/content/about-me/about-me.component.scss"],"names":[],"mappings":"AAGA;EACI,cAAA;EACA,yBAAA;AAFJ;AAII;EACI,uBAAA;EACA,eAAA;AAFR","sourcesContent":["@use '@angular/material' as mat;\n@import '../../../scss/import';\n\n:host {\n    display: block;\n    background-color: mat.m2-get-color-from-palette($candy-app-primary);\n\n    * {\n        color: white!important;\n        font-size: 1rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
