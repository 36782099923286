export class Quote {
    public author;
    public company;
    public quote;

    public constructor(author, company, quote) {
        this.author = author;
        this.company = company;
        this.quote = quote;
    }
}
