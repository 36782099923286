// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-list-base .mat-mdc-list-item {
  border-bottom: 1px solid #dee2e6;
  height: 54px;
  font-size: 14px;
}

.mat-mdc-list-text {
  padding-left: 0.5rem !important;
}

.mat-mdc-icon,
.mat-mdc-list-text {
  color: rgb(100, 100, 100);
}`, "",{"version":3,"sources":["webpack://./src/app/layout/menu/menu.component.scss","webpack://./src/scss/vars.scss"],"names":[],"mappings":"AAEA;EACI,gCAAA;EACA,YAAA;EACA,eAAA;AADJ;;AAIA;EACI,+BAAA;AADJ;;AAIA;;EAEI,yBCVQ;ADSZ","sourcesContent":["@import '../../../scss/vars.scss';\n\n.mat-mdc-list-base .mat-mdc-list-item {\n    border-bottom: 1px solid $border-color;\n    height: 54px;\n    font-size: 14px;\n}\n\n.mat-mdc-list-text {\n    padding-left: 0.5rem !important;\n}\n\n.mat-mdc-icon,\n.mat-mdc-list-text {\n    color: $dark-grey;\n}\n","$border-color: #dee2e6;\n$text-color: #53638c;\n\n$light-grey: rgb(215, 215, 215);\n$dark-grey: rgb(100, 100, 100);\n\n$breakpoints: (\n    sm: 600px,\n    md: 960px,\n    lg: 1280px,\n    xl: 1920px,\n);\n\n$gutter: 1.5em;\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
