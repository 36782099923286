import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ToggleSidenav } from '@actions';
import { LayoutState } from '@states';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ChatComponent } from '@dialogs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    @Select(LayoutState.smallScreen)
    smallScreen$: Observable<boolean>;

    constructor(private store: Store, private dialog: MatDialog) {}

    toggleSidenav() {
        this.store.dispatch(new ToggleSidenav());
    }

    openChat() {
        this.dialog.open(ChatComponent, {
            width: '320px',
        });
    }
}
