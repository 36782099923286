import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Quote } from '@models';

@Injectable({
    providedIn: 'root',
})
export class QuoteService {
    private endpoint = '/assets/data/quotes.json';

    constructor(private http: HttpClient) {}

    public getQuotes(): Observable<Quote[]> {
        return this.http.get(this.endpoint).pipe(
            map((data: any) => {
                const quotes: Quote[] = [];

                for (const quoteData of data) {
                    quotes.push(new Quote(quoteData.author, quoteData.company, quoteData.quote));
                }

                return quotes.reverse();
            }),
        );
    }
}
