// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-card {
  display: flex;
  height: 100%;
}
.mat-mdc-card .mat-mdc-card-title {
  font-size: 15px;
  font-weight: bold;
}
.mat-mdc-card .mat-mdc-card-content {
  font-size: 13px;
  flex-grow: 1;
}
.mat-mdc-card picture,
.mat-mdc-card picture img {
  max-width: 100%;
}
.mat-mdc-card .mdc-button {
  padding: 0 8px;
  max-height: 28px;
}`, "",{"version":3,"sources":["webpack://./src/app/content/certificates-training/certificates-training.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;AACJ;AACI;EACI,eAAA;EACA,iBAAA;AACR;AAEI;EACI,eAAA;EACA,YAAA;AAAR;AAGI;;EAEI,eAAA;AADR;AAII;EACI,cAAA;EACA,gBAAA;AAFR","sourcesContent":[".mat-mdc-card {\n    display: flex;\n    height: 100%;\n\n    .mat-mdc-card-title {\n        font-size: 15px;\n        font-weight: bold;\n    }\n\n    .mat-mdc-card-content {\n        font-size: 13px;\n        flex-grow: 1;\n    }\n\n    picture,\n    picture img {\n        max-width: 100%;\n    }\n\n    .mdc-button {\n        padding: 0 8px;\n        max-height: 28px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
