import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { QuoteService } from '@services';
import { Quote } from '@models';
import { GetQuotes } from '@actions';

interface QuotesStateModel {
    quotes: Quote[];
    loading: boolean;
}

@Injectable()
@State<QuotesStateModel>({
    name: 'quotes',
    defaults: {
        quotes: [],
        loading: true,
    },
})
export class QuotesState {
    constructor(private quoteService: QuoteService) {}

    @Action(GetQuotes)
    getQuotes(ctx: StateContext<QuotesStateModel>) {
        return this.quoteService.getQuotes().pipe(
            tap((quotes: Quote[]) => {
                const state = ctx.getState();
                return ctx.setState({
                    ...state,
                    quotes: quotes,
                    loading: false,
                });
            }),
        );
    }

    @Selector()
    public static loading(state: QuotesStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static quotes(state: QuotesStateModel): Quote[] {
        return state.quotes;
    }

    private static shuffleArray(array: Quote[]): Quote[] {
        const entries = array.slice();
        for (let i = entries.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [entries[i], entries[j]] = [entries[j], entries[i]]; // eslint-disable-line no-param-reassign
        }
        return entries;
    }
}
