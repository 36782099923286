import { Component, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { LayoutState } from '@states';

@Component({
    selector: 'app-go-to-top',
    templateUrl: './go-to-top.component.html',
    styleUrls: ['./go-to-top.component.scss'],
})
export class GoToTopComponent {
    @Select(LayoutState.scrolling)
    isActive$: Observable<boolean>;

    @HostListener('click')
    onClick() {
        window.document.getElementById('top').scrollIntoView({
            behavior: 'smooth',
        });
    }
}
