// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  color: white;
  font-size: 1em;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.9);
}

@media screen and (min-width: 600px) {
  h1 {
    font-size: 1.75em;
  }
}
@media screen and (min-width: 1280px) {
  h1 {
    font-size: 2.5em;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/content/addicted/addicted.component.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;EACA,cAAA;EACA,2CAAA;AADJ;;AAIA;EACI;IACI,iBAAA;EADN;AACF;AAIA;EACI;IACI,gBAAA;EAFN;AACF","sourcesContent":["@import '../../../scss/import';\n\nh1 {\n    color: white;\n    font-size: 1em;\n    text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.9);\n}\n\n@media screen and (min-width: map_get($breakpoints, sm)) {\n    h1 {\n        font-size: 1.75em;\n    }\n}\n\n@media screen and (min-width: map_get($breakpoints, lg)) {\n    h1 {\n        font-size: 2.5em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
