import { Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Project } from '@models';
import { ProjectService } from '@services';

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit {
    @Input()
    project: Project;

    @Input()
    isFeatured = false;

    showDetails = false;
    normalImage: string;
    webpImage: string;

    constructor(private projectService: ProjectService, private renderer: Renderer2, private container: ElementRef) {}

    ngOnInit() {
        this.normalImage = this.project.getImageUri();
        this.webpImage = this.project.getImageUri(true);
    }

    @HostListener('mouseenter')
    onMouseEnter() {
        this.renderer.addClass(this.container.nativeElement, 'zoom');
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.renderer.removeClass(this.container.nativeElement, 'zoom');
    }

    onClick() {
        if (this.isFeatured) {
            window.open(this.project.link);
        } else {
            const showDetails = this.showDetails;
            // TODO Fixme
            // this.projectService.onProjectSelected.next();
            this.showDetails = !showDetails;
        }
    }
}
