import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-brands',
    templateUrl: './brands.component.html',
    styleUrls: ['./brands.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandsComponent {
    brands = ['dpgmedia', 'gs1belu', 'vente-exclusive', 'vptech', 'senseo', 'belfius', 'digicat', 'g4s', 'groupjoos', 'medtronic', 'procter_and_gamble', 'rtv', 'zibbra'];

    getNormalImage(brandName): string {
        return '/assets/img/brands/' + brandName + '.png';
    }

    getWebpImage(brandName): string {
        return '/assets/img/brands/' + brandName + '.webp';
    }
}
