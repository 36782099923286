import { Component } from '@angular/core';
import { Section } from '@models';
import { Store } from '@ngxs/store';
import { ActivateSection } from '@actions';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-intro',
    templateUrl: './intro.component.html',
    styleUrls: ['./intro.component.scss'],
})
export class IntroComponent {
    public Section = Section;

    constructor(private store: Store, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        iconRegistry.addSvgIcon('linkedin', sanitizer.bypassSecurityTrustResourceUrl('assets/img/iconmonstr-linkedin-1.svg'));
        iconRegistry.addSvgIcon('skype', sanitizer.bypassSecurityTrustResourceUrl('assets/img/iconmonstr-skype-1.svg'));
    }

    scrollTo(section: Section): void {
        this.store.dispatch(new ActivateSection(section));
    }
}
