// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 30vh;
}
.wrapper > .background {
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.wrapper > .content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 2em;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media screen and (min-width: 600px) {
  .wrapper,
  .wrapper > .background {
    height: 50vh;
  }
}
@media screen and (min-width: 1280px) {
  .wrapper,
  .wrapper > .background {
    height: 80vh;
  }
}
::ng-deep app-parallax > .wrapper > .background > .simpleParallax {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/parallax/parallax.component.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;AADJ;AAGI;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AADR;AAII;EACI,kBAAA;EACA,OAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,cAAA;EACA,OAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AAFR;;AAMA;EACI;;IAEI,YAAA;EAHN;AACF;AAMA;EACI;;IAEI,YAAA;EAJN;AACF;AAQI;EACI,WAAA;AANR","sourcesContent":["@import '../../../../scss/import';\n\n.wrapper {\n    width: 100%;\n    position: relative;\n    overflow: hidden;\n    height: 30vh;\n\n    > .background {\n        height: 30vh;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        text-align: center;\n    }\n\n    > .content {\n        position: absolute;\n        left: 0;\n        top: 0;\n        right: 0;\n        bottom: 0;\n        padding: 0 2em;\n        flex: 1;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        text-align: center;\n    }\n}\n\n@media screen and (min-width: map_get($breakpoints, sm)) {\n    .wrapper,\n    .wrapper > .background {\n        height: 50vh;\n    }\n}\n\n@media screen and (min-width: map_get($breakpoints, lg)) {\n    .wrapper,\n    .wrapper > .background {\n        height: 80vh;\n    }\n}\n\n::ng-deep {\n    app-parallax > .wrapper > .background > .simpleParallax {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
