import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { ProjectService } from '@services';
import { Project } from '@models';
import { GetProjects } from '@actions';

interface ProjectsStateModel {
    projects: Project[];
    loading: boolean;
}

@Injectable()
@State<ProjectsStateModel>({
    name: 'projects',
    defaults: {
        projects: [],
        loading: true,
    },
})
export class ProjectsState {
    constructor(private projectService: ProjectService) {}

    @Action(GetProjects)
    getProjects(ctx: StateContext<ProjectsStateModel>) {
        return this.projectService.getProjects().pipe(
            tap((projects: Project[]) => {
                const state = ctx.getState();
                return ctx.setState({
                    ...state,
                    projects: projects,
                    loading: false,
                });
            }),
        );
    }

    @Selector()
    public static loading(state: ProjectsStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static projects(state: ProjectsStateModel): Project[] {
        return state.projects;
    }

    @Selector()
    public static featured(state: ProjectsStateModel): Project[] {
        const featured = state.projects.filter(_ => _.isFeatured());
        featured.sort((a, b) => {
            return b.id - a.id;
        });
        return featured;
    }
}
