import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnInit,
    ViewChildren,
    ViewChild,
    QueryList,
    Renderer2,
    OnDestroy,
    ChangeDetectorRef,
    HostListener,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { Quote } from '@models';
import { QuotesState } from '@states';
import { GetQuotes } from '@actions';

@Component({
    selector: 'app-quotes',
    templateUrl: './quotes.component.html',
    styleUrls: ['./quotes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuotesComponent implements OnInit, AfterViewInit, OnDestroy {
    @Select(QuotesState.loading)
    loading$: Observable<boolean>;

    @Select(QuotesState.quotes)
    quotes$: Observable<Quote[]>;

    @ViewChild('containerElement')
    containerElement: ElementRef;

    @ViewChildren('quoteElement')
    quoteElements: QueryList<ElementRef>;

    public intervalPercentage = 100;

    private readonly intervalDuration = 5000;
    private intervalValue: number;
    private countdownInterval: number;
    private currentElm: number;

    constructor(private store: Store, private renderer: Renderer2, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.store.dispatch(new GetQuotes());
    }

    ngAfterViewInit() {
        const subscription = this.quoteElements.changes.subscribe((changes: QueryList<ElementRef>) => {
            if (changes.first !== undefined) {
                this.currentElm = 0;
                this.renderer.addClass(changes.first.nativeElement, 'is-ref');
                subscription.unsubscribe();
            }
        });
        setTimeout(() => {
            this.restartCountdown();
        }, 500);
    }

    ngOnDestroy() {
        clearInterval(this.countdownInterval);
    }

    onClickNext() {
        this.activateNext();
        this.restartCountdown();
    }

    onClickPrevious() {
        this.activatePrevious();
        this.restartCountdown();
    }

    @HostListener('mouseenter')
    onMouseEnter() {
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
        }
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.restartCountdown(this.intervalValue);
    }

    private restartCountdown(startFrom: number = this.intervalDuration) {
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
        }
        if (this.quoteElements.length === 0) {
            return;
        }
        const interval = 50;
        this.intervalValue = startFrom;
        this.countdownInterval = setInterval(() => {
            this.intervalValue = this.intervalValue - interval;
            if (this.intervalValue <= 0) {
                this.activateNext();
                this.intervalValue = this.intervalDuration;
            }
            this.intervalPercentage = Math.round((this.intervalValue / this.intervalDuration) * 100);
            this.cdr.detectChanges();
        }, interval);
    }

    private activateNext() {
        this.transitionStart();
        this.currentElm = this.next(this.currentElm);
        this.renderer.removeClass(this.containerElement.nativeElement, 'is-reversing');
        this.transitionEnd();
    }

    private activatePrevious() {
        this.transitionStart();
        this.currentElm = this.prev(this.currentElm);
        this.renderer.addClass(this.containerElement.nativeElement, 'is-reversing');
        this.transitionEnd();
    }

    private transitionStart() {
        const elm = this.quoteElements.toArray()[this.currentElm];
        if (elm && elm.nativeElement.classList.contains('is-ref')) {
            this.renderer.removeClass(elm.nativeElement, 'is-ref');
        }
    }

    private transitionEnd() {
        let elm = this.quoteElements.toArray()[this.currentElm];
        this.renderer.addClass(elm.nativeElement, 'is-ref');
        this.renderer.setStyle(elm.nativeElement, 'order', '1');

        let index = this.currentElm;
        let i, j, ref;
        for (i = j = 2, ref = this.quoteElements.length; 2 <= ref ? j <= ref : j >= ref; i = 2 <= ref ? ++j : --j) {
            index = this.next(index);
            elm = this.quoteElements.toArray()[index];
            this.renderer.setStyle(elm.nativeElement, 'order', i);
        }

        this.renderer.removeClass(this.containerElement.nativeElement, 'is-set');
        setTimeout(() => {
            this.renderer.addClass(this.containerElement.nativeElement, 'is-set');
        }, 50);
    }

    private next(index: number): number {
        if (index + 1 < this.quoteElements.length) {
            return index + 1;
        }
        return 0;
    }

    private prev(index: number): number {
        if (index > 0) {
            return index - 1;
        }
        return this.quoteElements.length - 1;
    }
}
