// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div[mat-dialog-content],
div[mat-dialog-actions] {
  display: flex;
}

div[mat-dialog-content] {
  flex-direction: column;
}
div[mat-dialog-content] > * {
  width: 100%;
}

div[mat-dialog-actions] {
  flex-direction: row;
}
div[mat-dialog-actions] > button {
  margin-right: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/dialogs/chat/chat.component.scss"],"names":[],"mappings":"AAAA;;EAEI,aAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;AACI;EACI,WAAA;AACR;;AAGA;EACI,mBAAA;AAAJ;AAEI;EACI,oBAAA;AAAR","sourcesContent":["div[mat-dialog-content],\ndiv[mat-dialog-actions] {\n    display: flex;\n}\n\ndiv[mat-dialog-content] {\n    flex-direction: column;\n\n    > * {\n        width: 100%;\n    }\n}\n\ndiv[mat-dialog-actions] {\n    flex-direction: row;\n\n    > button {\n        margin-right: 0.5rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
