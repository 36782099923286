import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SkillGroup } from '@models';

@Injectable({
    providedIn: 'root',
})
export class SkillService {
    private endpoint = '/assets/data/skills.json';

    constructor(private http: HttpClient) {}

    public getSkills(): Observable<SkillGroup[]> {
        return this.http.get(this.endpoint).pipe(
            map((data: any) => {
                return data as SkillGroup[];
            }),
        );
    }
}
