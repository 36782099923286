import { Component, inject, OnInit, Renderer2 } from '@angular/core';
import { ScrollService } from '@services';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Store } from '@ngxs/store';
import { DismissShowcase, GetShowcaseDismissed, OnBreakpoint } from '@actions';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ShowcaseComponent } from './content/showcase/showcase.component';
import { LayoutState } from '@states';
import { Observable } from 'rxjs';
import { LayoutStateModel } from './shared/states/layout.state';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    showcaseDismissedAt$: Observable<Date> = inject(Store).select(LayoutState.showcaseDismissedAt);

    constructor(
        private renderer: Renderer2,
        private scrollService: ScrollService,
        private breakpointObserver: BreakpointObserver,
        private store: Store,
        private bottomSheet: MatBottomSheet,
    ) {}

    ngOnInit() {
        this.scrollService.setRenderer(this.renderer);

        this.breakpointObserver.observe([Breakpoints.HandsetPortrait]).subscribe((result: BreakpointState) => {
            this.store.dispatch(new OnBreakpoint(result));
        });

        // @ts-ignore
      this.store.dispatch(new GetShowcaseDismissed()).subscribe((value: {layout: LayoutStateModel}) => {
            if (value.layout.showcaseDismissedAt === null) {
                this.openShowcase();
            }
        });
    }

    openShowcase() {
        this.bottomSheet
            .open(ShowcaseComponent)
            .afterDismissed()
            .subscribe(() => {
                this.store.dispatch(new DismissShowcase());
            });
    }
}
