// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: fixed;
  bottom: 1em;
  left: 1em;
  z-index: 999;
}
:host button {
  background-color: red;
  color: white;
  border-radius: 50%;
  border: 2px solid white;
  width: 42px;
  height: 42px;
}
:host mat-icon {
  margin-top: -3px;
}`, "",{"version":3,"sources":["webpack://./src/app/content/showcase/button/button.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,WAAA;EACA,SAAA;EACA,YAAA;AACJ;AACI;EACI,qBAAA;EACA,YAAA;EACA,kBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;AACR;AAEI;EACI,gBAAA;AAAR","sourcesContent":[":host {\n    position: fixed;\n    bottom: 1em;\n    left: 1em;\n    z-index: 999;\n\n    button {\n        background-color: red;\n        color: white;\n        border-radius: 50%;\n        border: 2px solid white;\n        width: 42px;\n        height: 42px;\n    }\n\n    mat-icon {\n        margin-top: -3px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
