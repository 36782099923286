// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  background-color: #2196f3;
}
:host * {
  color: white;
}
:host .mat-icon {
  zoom: 2;
}
:host h3 {
  font-size: 1.25rem;
}`, "",{"version":3,"sources":["webpack://./src/app/content/services/services.component.scss"],"names":[],"mappings":"AAGA;EACI,cAAA;EACA,yBAAA;AAFJ;AAII;EACI,YAAA;AAFR;AAUI;EACI,OAAA;AARR;AAWI;EACI,kBAAA;AATR","sourcesContent":["@use '@angular/material' as mat;\n@import '../../../scss/import';\n\n:host {\n    display: block;\n    background-color: mat.m2-get-color-from-palette($candy-app-primary);\n\n    * {\n        color: white;\n    }\n\n    .col-3 {\n        .text-center {\n        }\n    }\n\n    .mat-icon {\n        zoom: 2;\n    }\n\n    h3 {\n        font-size: 1.25rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
