// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  background-color: rgba(0, 0, 0, 0.05);
}

.mdc-list-item {
  justify-content: space-between;
  flex-direction: row;
  height: 32px !important;
  padding-right: 16px !important;
}
.mdc-list-item h4 {
  flex: 1 0 auto;
  margin: 0;
  font-size: 16px;
  line-height: 16px;
}
.mdc-list-item ul {
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
}
.mdc-list-item ul > li {
  float: left;
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 0.5em;
}
.mdc-list-item ul > li.active {
  background-color: #2196f3;
}
.mdc-list-item ul > li:last-of-type {
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/content/languages/languages.component.scss"],"names":[],"mappings":"AAGA;EACI,cAAA;EACA,qCAAA;AAFJ;;AAKA;EACI,8BAAA;EACA,mBAAA;EACA,uBAAA;EACA,8BAAA;AAFJ;AAII;EACI,cAAA;EACA,SAAA;EACA,eAAA;EACA,iBAAA;AAFR;AAKI;EACI,cAAA;EACA,SAAA;EACA,UAAA;AAHR;AAKQ;EACI,WAAA;EACA,cAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,oCAAA;EACA,mBAAA;AAHZ;AAKY;EACI,yBAAA;AAHhB;AAMY;EACI,eAAA;AAJhB","sourcesContent":["@use '@angular/material' as mat;\n@import '../../../scss/import';\n\n:host {\n    display: block;\n    background-color: rgba(0, 0, 0, 0.05);\n}\n\n.mdc-list-item {\n    justify-content: space-between;\n    flex-direction: row;\n    height: 32px !important;\n    padding-right: 16px !important;\n\n    h4 {\n        flex: 1 0 auto;\n        margin: 0;\n        font-size: 16px;\n        line-height: 16px;\n    }\n\n    ul {\n        flex: 0 0 auto;\n        margin: 0;\n        padding: 0;\n\n        > li {\n            float: left;\n            display: block;\n            width: 1em;\n            height: 1em;\n            border-radius: 50%;\n            background-color: rgba(0, 0, 0, 0.1);\n            margin-right: 0.5em;\n\n            &.active {\n                background-color: mat.m2-get-color-from-palette($candy-app-primary);\n            }\n\n            &:last-of-type {\n                margin-right: 0;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
