import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Project } from '@models/project';

@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    public endpoint = '/assets/data/projects.json';
    public onProjectSelected = new Subject();

    constructor(private http: HttpClient) {}

    public getProjects(): Observable<Project[]> {
        return this.http.get(this.endpoint).pipe(
            map((data: any) => {
                const projects: Project[] = [];

                for (const projectData of data) {
                    projects.push(
                        new Project(
                            parseInt(projectData.id, 10),
                            projectData.domain,
                            projectData.name,
                            projectData.image,
                            projectData.description,
                            projectData.link,
                            projectData.featured,
                            projectData.highlights,
                        ),
                    );
                }

                return projects;
            }),
        );
    }
}
