import { Component, OnInit, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { Project } from '@models';
import { ProjectService } from '@services';
import { ProjectsState } from '@states';
import { GetProjects } from '@actions';

import { ProjectComponent } from '../../shared/components/project/project.component';

@Component({
    selector: 'app-portfolio',
    templateUrl: './portfolio.component.html',
    styleUrls: ['./portfolio.component.scss'],
})
export class PortfolioComponent implements OnInit, OnDestroy {
    @Select(ProjectsState.loading)
    loading$: Observable<boolean>;

    @Select(ProjectsState.projects)
    projects$: Observable<Project[]>;

    @ViewChildren(ProjectComponent)
    projectComponents: QueryList<ProjectComponent>;

    constructor(private store: Store, private projectService: ProjectService) {}

    ngOnInit() {
        this.store.dispatch(new GetProjects());

        this.projectService.onProjectSelected.subscribe(() => {
            this.projectComponents.forEach(projectComponent => (projectComponent.showDetails = false));
        });
    }

    ngOnDestroy() {
        this.projectService.onProjectSelected.unsubscribe();
    }
}
