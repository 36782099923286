export class Message {
    public name: string;
    public email: string;
    public phone: string;
    public message: string;

    public constructor(name: string, email: string, phone: string, message: string) {
        this.name = name;
        this.email = email;
        this.phone = phone;
        this.message = message;
    }
}
