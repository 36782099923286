import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { CloseSidenav, OpenSidenav } from '@actions';
import { LayoutState } from '@states';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
    @Select(LayoutState.sidenavOpen)
    opened$: Observable<boolean>;

    constructor(private store: Store) {}

    onOpen() {
        this.store.dispatch(new OpenSidenav());
    }

    onClose() {
        this.store.dispatch(new CloseSidenav());
    }
}
