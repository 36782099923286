export enum Section {
    INTRO,
    ABOUT_ME,
    SKILLS,
    LANGUAGES,
    CERTIFICATES,
    SERVICES,
    FEATURED,
    HISTORY,
    BRANDS,
    PORTFOLIO,
}
