// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `canvas {
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 56px);
  z-index: -1;
  background-color: white;
  background-image: url(/assets/img/background.png);
  opacity: 0.4;
}

@media screen and (min-width: 600px) {
  canvas {
    height: calc(100vh - 64px);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/layout/background/background.component.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,0BAAA;EACA,WAAA;EACA,uBAAA;EACA,iDAAA;EACA,YAAA;AADJ;;AAIA;EACI;IACI,0BAAA;EADN;AACF","sourcesContent":["@import '../../../scss/import';\n\ncanvas {\n    position: static;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: calc(100vh - 56px);\n    z-index: -1;\n    background-color: white;\n    background-image: url(/assets/img/background.png);\n    opacity: 0.4;\n}\n\n@media screen and (min-width: map_get($breakpoints, sm)) {\n    canvas {\n        height: calc(100vh - 64px);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
