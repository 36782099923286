import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-showcase-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ShowcaseButtonComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
