// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-toolbar {
  border-bottom: 1px solid #dee2e6;
}

.button-row button {
  margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/header/header.component.scss"],"names":[],"mappings":"AAEA;EACI,gCAAA;AADJ;;AAIA;EACI,iBAAA;AADJ","sourcesContent":["@import '../../../scss/vars';\n\n.mat-toolbar {\n    border-bottom: 1px solid $border-color;\n}\n\n.button-row button {\n    margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
